import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { AzureBlobService } from 'src/app/Services/AzureBlobServices/azure-blob.service';
import { azureEndPoints } from 'src/app/AzureConfig/AzureEndPoints';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  imageURL:string;
  isAdmin:boolean;
  constructor(private blobsService:AzureBlobService,private router:Router) 
  { 
      this.imageURL="";
  }

   async ngOnInit() {
    window.addEventListener("storage", this.onStorageChange);
    this.isAdmin = (sessionStorage.getItem('roles') == undefined || sessionStorage.getItem('roles') == "undefined")? false : true;
    this.imageURL=await this.getHomeBlobs(azureEndPoints.azureBlobs.homePageContainer);
  }

  onStorageChange(){
    this.isAdmin = (sessionStorage.getItem('roles') == undefined || sessionStorage.getItem('roles') == "undefined")? false : true;
  }

  async onBenefitsClick()
  {
    window.open(await this.getHomeBlobs(azureEndPoints.azureBlobs.benefitsContainer),"_blank")
  }
  async onHowToClick()
  {
    window.open(await this.getHomeBlobs(azureEndPoints.azureBlobs.howToContainer),"_blank")
  }
  onMenusClick()
  {
    this.router.navigate(['menus']);
  }
  onGraphicsClick()
  {
    this.router.navigate(['graphics']);
  }

  onUploadClick()
  {
    if(this.isAdmin){
      this.router.navigate(['upload']);
    }
  }

  async getHomeBlobs(container:string){

    var result;
    await this.blobsService.getAllBlobs(container).then(blobs=>{
      result=blobs[0];
    })

    return this.blobsService.loadBlob(container,result);
  }
}
