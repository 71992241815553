import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { azureEndPoints } from '../../AzureConfig/AzureEndPoints';
import { Customer } from '../../Models/CustomerModel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  private URL:string=azureEndPoints.azureStorageTable.baseUrl+azureEndPoints.azureStorageTable.customerInfoTable+azureEndPoints.TableSASToken;
  constructor(private http:HttpClient) { }

  addCustomer(customer:Customer):Observable<Customer>
  {
    return this.http.post<Customer>(this.URL,JSON.stringify(customer),{headers:new HttpHeaders().set('Content-Type','application/json')})
  }
}
