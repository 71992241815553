import { Component, OnInit } from '@angular/core';
import { MenuService } from 'src/app/Services/MenuService/menu.service';
import { Menu } from 'src/app/Models/MenuModel';
import { Router} from '@angular/router';
import { DataService } from 'src/app/Services/DataService/data.service';

@Component({
  selector: 'app-menus-list',
  templateUrl: './menus-list.component.html',
  styleUrls: ['./menus-list.component.css']
})
export class MenusListComponent implements OnInit {

  menuItems:Menu[];
  constructor(private menuService:MenuService,private router:Router,private data:DataService) {}

  async ngOnInit() {
    this.menuItems=await this.menuService.getMenusListData();
  }

  navigateToMenuDetails(menuItem){
    
    this.data.Storage=menuItem;
    this.router.navigate(['menuDetails'])
  }

}
