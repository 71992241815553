import { StorageTableEntity } from "./StorageTableEntity";
import { Guid } from "guid-typescript";

export class Customer implements StorageTableEntity
{
    PartitionKey: string;
    RowKey: Guid;
    constructor(public firstName?:string,public lastName?:string,public email?:string,public outletId?:number)
    {
        var guid=Guid.create()
        this.RowKey=guid["value"];
        this.PartitionKey="customers";
    }
}