export const azureEndPoints = 
{
  BLOBSASToken:"__BLOBSASToken__",
  TableSASToken: "__TableSASToken__",
  azureBlobs: 
  {
    accName: "__AccName__",
    baseUrl:"__SABaseBlobURL__",
    homePageContainer:"home-page",
    benefitsContainer:"benefits",
    howToContainer:"how-to",
    menusContainer:"menus",
    graphicsContainer:"graphics"
  },
  azureStorageTable:
  {
    baseUrl:"__SABaseTableURL__",
    customerInfoTable:"CustomerInfo",
  },
  azureAuth:
  {
    clientId: "__ClientID__",
    redirectUri: "__RedirectURI__",
    authority: "__Authority__",
    scopes: "__Scopes__"
  }

};

export const captchaTest = 
{
   Key:"6LdYdaQUAAAAAItJ32miygcLd7IXO2vi2Of92V42"
}