import { Component, OnInit } from '@angular/core';
import { GraphicsService } from 'src/app/Services/GraphicsService/graphics.service';
import { Brand } from 'src/app/Models/BrandsModel';
import {map} from 'bluebird';
import { ZipService } from 'src/app/Services/ZipService/zip.service';
import { ImageModel } from 'src/app/Models/ImageModel';

@Component({
  selector: 'app-graphics',
  templateUrl: './graphics.component.html',
  styleUrls: ['./graphics.component.css']
})
export class GraphicsComponent implements OnInit {

  BrandsList:Brand[]
  SelectedSectionImages:ImageModel[];
  SelectedBrand:Brand;
  IsBrandSelected:boolean;

  IsProducts: boolean = true;
  IsOther: boolean = true;
  IsLogos: boolean = true;
  IsUberEats: boolean;
  IsDoorDash: boolean;
  IsGrubhub: boolean;
  selectedIndex: number;
  CurrentBrandImages: ImageModel[];
  constructor(private graphicService:GraphicsService, private zipService: ZipService) 
  {
    this.selectedIndex = 0;
    this.IsBrandSelected=false;
    this.SelectedBrand = new Brand();
    this.CurrentBrandImages = [];
  }

  async ngOnInit() 
  {  
   this.BrandsList= await this.graphicService.getBrandsData();
   //this.selectProductsSection(); // By Sanjiv - US96434 - AllBrands
    this.selectProductsAndlogosAndOtherSection(); // By Sanjiv - US96434 - AllBrands
  }
  // By Sanjiv - US96434 - AllBrands
  async selectProductsAndlogosAndOtherSection(_index?) {
    this.IsUberEats = false;
    this.IsDoorDash = false;
    this.IsGrubhub = false;

    if (this.IsBrandSelected) {
      if (this.SelectedBrand.brandName == "All Brands") {
        this.BrandsList.forEach(brand => {
          if (brand.brandName != "All Brands") {
            let brandElement = <HTMLInputElement>document.getElementById(brand.brandName);
            brandElement.checked = false;
          }
        })
        this.SelectedSectionImages = await this.graphicService.getDefaultProductsandLogosandOtherImagesData();
        this.CurrentBrandImages = [];
      }
      else {
        let AllBrandsElement = <HTMLInputElement>document.getElementById("All Brands");
        AllBrandsElement.checked=false;
        let element = <HTMLInputElement>document.getElementById(this.SelectedBrand.brandName);
        let Ischecked = element.checked;
        let matchingBrandImages = [];
        if (Ischecked)
        {
          this.SelectedBrand.brandLogos.forEach(item => {
            let model = new ImageModel();
            model.URL = item;
            model.IsOtherImage = false;
            if(this.CurrentBrandImages.every(i => i.URL != item)) this.CurrentBrandImages.push(model);
          });

          this.SelectedBrand.brandProducts.forEach(item => {
            let model = new ImageModel();
            model.URL = item;
            model.IsLogosImage = false;
            model.IsOtherImage = false;
            if(this.CurrentBrandImages.every(i => i.URL != item)) this.CurrentBrandImages.push(model);
          });



          this.SelectedBrand.other.forEach(item => {
            let model = new ImageModel();
            model.IsLogosImage = false;
            model.URL = item;
            if(this.CurrentBrandImages.every(i => i.URL != item)) this.CurrentBrandImages.push(model);
          });
        }
        if (!Ischecked) {
          this.CurrentBrandImages.forEach(item => {
            if (decodeURIComponent(item.URL).indexOf('/'+this.SelectedBrand.brandName+'/')>-1) {              
              matchingBrandImages.push(item);              
            }
          })
          this.CurrentBrandImages = this.CurrentBrandImages.filter(function (n) { return !this.has(n) }, new Set(matchingBrandImages));   
        }
        this.SelectedSectionImages = this.CurrentBrandImages;
      }      
    }
    else
      this.SelectedSectionImages = await this.graphicService.getDefaultProductsandLogosandOtherImagesData();

    this.filterImagesByTabSelected();
    this.sort();
  }

  sort() {
    const customSort = (a: ImageModel, b: ImageModel) => {
      const getBrandIndex = (url: string) => {
        const brandName = this.getNameFromURL(url).split('_Order_')[0];
        const index = this.BrandsList.findIndex(i => i.brandName.replace('\'', "") === brandName);
        return index === -1 ? Number.MAX_SAFE_INTEGER : index;
      };
      const indexA = getBrandIndex(a.URL);
      const indexB = getBrandIndex(b.URL);
      if (indexA === indexB && a.IsOtherImage) return 1;
      return indexA - indexB;
    };

    const thenSort = (a: boolean, b: boolean) => {
      if (a !== b) return a ? -1 : 1;
      return 0;
    };

    this.SelectedSectionImages
      .sort(customSort) //Order By Brand
      .sort((a, b) => thenSort(this.getNameFromURL(a.URL).toLowerCase().includes('cup'), this.getNameFromURL(b.URL).toLowerCase().includes('cup'))) //Order single cups then Group
      .sort((a, b) => thenSort(a.IsOtherImage, b.IsOtherImage)) //Other
      .sort((a, b) => thenSort(!a.IsLogosImage && !a.IsOtherImage, !b.IsLogosImage && !b.IsOtherImage)) //Product
      .sort((a, b) => thenSort(a.IsLogosImage, b.IsLogosImage)); // Logos
  }

  filterImagesByTabSelected() {
    if (this.IsLogos && this.IsProducts && this.IsOther) return;

    if (this.IsLogos) {
      this.SelectedSectionImages = this.SelectedSectionImages.filter(i => i.IsLogosImage);
      return;
    }

    if (this.IsOther) {
      this.SelectedSectionImages = this.SelectedSectionImages.filter(i => i.IsOtherImage);
      return;
    }

    this.SelectedSectionImages = this.SelectedSectionImages.filter(i => !i.IsLogosImage && !i.IsOtherImage);
  }

  async selectProductsSection(){

    this.IsProducts=true;
    this.IsLogos = false;
    this.IsOther = false;
    this.IsUberEats = false;
    this.IsDoorDash = false;
    this.IsGrubhub = false;
    if (this.IsBrandSelected) {
      if (this.SelectedBrand.brandName == "All Brands") 
        this.SelectedSectionImages = await this.graphicService.getDefaultProductsImagesData();
      // By Sayed - US96434 - AllBrands
      else {        
        let matchingBrandImages = [];        
        this.CurrentBrandImages.forEach(image => {
          if (image.URL.indexOf("/Product%20Images/") != -1) {
            matchingBrandImages.push(image);            
          }            
        })        
        this.SelectedSectionImages = matchingBrandImages;
      }       
    }
    else
      this.SelectedSectionImages = await this.graphicService.getDefaultProductsImagesData();

    this.sort();
  }

  async selectLogosSection(){
    
    this.IsLogos = true;
    this.IsProducts = false;
    this.IsOther = false;
    this.IsUberEats = false;
    this.IsDoorDash = false;
    this.IsGrubhub = false;
    if (this.IsBrandSelected) {
      if (this.SelectedBrand.brandName == "All Brands") 
        this.SelectedSectionImages = await this.graphicService.getDefaultLogosData();
      // By Sayed - US96434 - AllBrands
      else {        
        let matchingBrandImages = [];        
        this.CurrentBrandImages.forEach(image => {
          if (image.URL.indexOf("/Logos/") != -1) {
            matchingBrandImages.push(image);            
          }            
        })        
        this.SelectedSectionImages = matchingBrandImages;
      }
    }
    else 
      this.SelectedSectionImages = await this.graphicService.getDefaultLogosData();

    this.sort();
  }

  async selectOtherSection() {
    this.IsOther = true;
    this.IsLogos = false;
    this.IsProducts = false;
    this.IsUberEats = false;
    this.IsDoorDash = false;
    this.IsGrubhub = false;
    if (this.IsBrandSelected) {
      if (this.SelectedBrand.brandName == "All Brands")
        this.SelectedSectionImages = await this.graphicService.getDefaultOtherImagesData();
      // By Sayed - US96434 - AllBrands
      else {        
        let matchingBrandImages = [];        
        this.CurrentBrandImages.forEach(image => {
          if (image.URL.indexOf("/Other/") != -1) {
            matchingBrandImages.push(image);            
          }            
        })        
        this.SelectedSectionImages = matchingBrandImages;
      }
    }
    else 
      this.SelectedSectionImages = await this.graphicService.getDefaultOtherImagesData();

    this.sort();
  }

  // Code Commented By Sanjiv - US96434 - AllBrands
  //async selectUberEatsSection() {

  //  this.IsProducts = false;
  //  this.IsLogos = false;
  //  this.IsUberEats = true;
  //  this.IsDoorDash = false;
  //  this.IsGrubhub = false;
  //  if (this.IsBrandSelected)
  //    this.SelectedSectionImages = this.SelectedBrand.brandUberEats;
  //  else
  //  this.SelectedSectionImages = await this.graphicService.getDefaultUberEatsData();
  //}

  //async selectDoorDashSection() {

  //  this.IsProducts = false;
  //  this.IsLogos = false;
  //  this.IsUberEats = false;
  //  this.IsDoorDash = true;
  //  this.IsGrubhub = false;
  //  if (this.IsBrandSelected)
  //    this.SelectedSectionImages = this.SelectedBrand.brandDoorDash;
  //  else
  //  this.SelectedSectionImages = await this.graphicService.getDefaultDoorDashData();
  //}

  //async selectGrubhubSection() {

  //  this.IsProducts = false;
  //  this.IsLogos = false;
  //  this.IsUberEats = false;
  //  this.IsDoorDash = false;
  //  this.IsGrubhub = true;
  //  if (this.IsBrandSelected)
  //    this.SelectedSectionImages = this.SelectedBrand.brandGrubhub;
  //  else
  //  this.SelectedSectionImages = await this.graphicService.getDefaultGrubhubData(); 
  //}


  selectBrand(brand,_index) {
    this.selectedIndex = _index;

    this.IsBrandSelected = true;
    this.SelectedBrand=brand;
    //this.selectProductsSection(); // By Sanjiv - US96434 - AllBrands
    this.selectProductsAndlogosAndOtherSection(_index); // By Sanjiv - US96434 - AllBrands
  }

  downloadImage(downloadLink){
    this.graphicService.downloadImage(downloadLink).subscribe(
      (res) => {

        var fileName = downloadLink.toString().split("/");
        fileName = fileName[5].toString().replace("%20", " ");
        fileName = fileName.toString().replace("%27", "");
        fileName = fileName.toString().split("?sv");
        let name = fileName[0].toString().split('_Order_');
        fileName = name.length > 1 ? name[1] : name [0];
        if (window.navigator.msSaveOrOpenBlob) {
          // IE 11

          window.navigator.msSaveOrOpenBlob(res.body, fileName);
        } else {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(res.body);
          let nameFromURL = this.getNameFromURL(downloadLink, 3).split('_Order_');
          a.download = (this.selectedIndex == 0 ? "All Brands" : (nameFromURL.length > 1 ? nameFromURL[1] : nameFromURL[0])) ||fileName;
          document.body.appendChild(a);
          a.click();
        }
      });  
    }

    downloadByGroup (imgs: ImageModel[], files_per_group: number=5) {
      return map(imgs, async (img: ImageModel) => { 
        let url = img.URL;
        let blob = await this.graphicService.download(url);
        let fileName = `${(this.IsLogos && this.IsProducts && this.IsOther) ? (img.IsLogosImage ? 'Logo_' : img.IsOtherImage ? 'Other_' : 'Product_') : ''
          }${this.getNameFromURL(url)}`;
        let name = fileName.split('_Order_');
        return [name.length > 1 ? name[1] : name[0], blob] as const;
       },{concurrency: files_per_group});
    }
        
     downloadAndZip () {
      let imgs = this.SelectedSectionImages.filter(f => f.IsSelected);
       if (imgs.length == 0) return;
       let zipFileName = (this.IsProducts && this.IsLogos) ? "CokeToGo" : (this.IsProducts ? "CokeToGo-Products" : this.IsOther ? "CokeToGo-Other" : "CokeToGo-Logos");
      return this.downloadByGroup(imgs, 5).then(res => this.zipService.exportZip(zipFileName, res));
    }

    getNameFromURL(url: string, index:number=1) {
      var urlSplit = url.split("/");
      return urlSplit[urlSplit.length-index].split("?sv")[0].split("%27").join("").split("%20").join(" ");
    }

    selectAllImages(){
      this.SelectedSectionImages.filter(i => !i.IsSelected).forEach(i => i.IsSelected = true);
    }

    deselectAllImages(){
      this.SelectedSectionImages.filter(i => i.IsSelected).forEach(i => i.IsSelected = false);
    }

    allImagesDeselected(){
      return this.SelectedSectionImages?.every(i => !i.IsSelected);
    }

    allImagesSelected(){
      return this.SelectedSectionImages?.every(i => i.IsSelected);
    }

  selectAllTabs() {
    if (this.IsProducts && this.IsLogos && this.IsOther) return;
    this.IsProducts = this.IsLogos = this.IsOther = true;
    this.selectProductsAndlogosAndOtherSection(this.selectedIndex);
  }
}  

