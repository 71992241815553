import { Injectable } from '@angular/core';
import { AzureBlobService } from '../AzureBlobServices/azure-blob.service';
import { azureEndPoints } from 'src/app/AzureConfig/AzureEndPoints';
import { Menu } from 'src/app/Models/MenuModel';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  menusContainerBaseURL=azureEndPoints.azureBlobs.baseUrl+azureEndPoints.azureBlobs.menusContainer+"/";
  constructor(private azureBlobService:AzureBlobService) 
  {
    
  }
 
   async getMenusListData()
  {
    var mainList=[];
    var foldersArray=[];
    var blobList;

    await this.azureBlobService.getAllBlobs(azureEndPoints.azureBlobs.menusContainer).then((result)=>{
      blobList=result;
    })
    
  blobList.forEach(element => {
  foldersArray.push(element.split('/')[0]);  
 });

 foldersArray = Array.from(new Set(foldersArray.map((item: any) => item)))
 //sorting folders Array
 foldersArray = foldersArray.sort(function (a, b) {
    return a.localeCompare(b);
 });
 foldersArray.forEach((item)=>{

  var menusingleFolder= blobList.filter((ele)=>ele.split('/')[0]==item)
  var menuitem=new Menu();
  menuitem.folderName=item;

  menusingleFolder.forEach(element => {
    var extension=element.split('.')[1];
    if(extension=="docx"|| extension=="doc")
    {
      menuitem.wordFile=this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.menusContainer,element)
    }
    if(extension=="png" || extension=="jpg")
    {
      menuitem.image=this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.menusContainer,element)
    }
    if(extension=="pptx")
    {
      menuitem.pptxFile=this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.menusContainer,element)
    }
    if(extension=="txt")
    {
      menuitem.textFile=this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.menusContainer,element)
    }
  });

  mainList.push(menuitem);
 });
     return mainList
   }

}
