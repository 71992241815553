import { Injectable } from '@angular/core';
import { azureEndPoints } from 'src/app/AzureConfig/AzureEndPoints';

declare var AzureStorage: any;
@Injectable({
  providedIn: 'root'
})


export class AzureBlobService {

  blobService:any
  constructor() 
  { 
    this.blobService = AzureStorage.Blob.createBlobServiceWithSas(azureEndPoints.azureBlobs.baseUrl,azureEndPoints.BLOBSASToken);
  }

 loadBlob(containerName:string,blobPath:string)
 {
  var sasUrl = this.blobService.getUrl(containerName, blobPath, azureEndPoints.BLOBSASToken, true);
  return sasUrl;
 }

  getAllBlobs(containerName)
 {
     var promise = new Promise((resolve, reject) => {
       
      var blobList=[];
       this.blobService.listBlobsSegmented(containerName, null, function (error, results) {
         if (error) {
          reject();
          } else {
          blobList=results.entries.map((item)=>item['name']);
          resolve(blobList);
         } 
       });
     });
     return promise;
  }
}
