import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';

@Injectable({
  providedIn: 'root'
})
export class ZipService {

  constructor() { }

  exportZip (fileName, items) {
    let zipFile: JSZip = new JSZip();
    items.forEach(item => zipFile.file(item[0], item[1]));
    zipFile.generateAsync({type: 'blob'}).then(zipFile => saveAs(zipFile, `${fileName}.zip`));
  }
}
