export class Brand
{
  constructor(public brandName?: string, public brandProducts?: string[], public brandLogos?: string[], public other?: string[],
    public brandUberEats?: string[], public brandDoorDash?: string[], public brandGrubhub?: string[], public checked?: boolean )
  {
        
        brandProducts=new Array<string>();
        brandLogos = new Array<string>();
        other = new Array<string>();
        brandUberEats = new Array<string>();
        brandDoorDash = new Array<string>();
        brandGrubhub = new Array<string>();
    }
}
