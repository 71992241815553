import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input('PrimaryTitle') primaryTitle:string;
  @Input('SecondaryTitle') secondaryTitle:string;
  @Input('IsPrimaryOnly') isPrimaryOnly:boolean;
  constructor() 
  { 
    this.isPrimaryOnly=false;

  }

  ngOnInit() {
  }

}
