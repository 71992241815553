import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/DataService/data.service';
import { Menu } from 'src/app/Models/MenuModel';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PlatformLocation } from '@angular/common';
import { captchaTest } from 'src/app/AzureConfig/AzureEndPoints';

declare var $:any
@Component({
  selector: 'app-menu-details',
  templateUrl: './menu-details.component.html',
  styleUrls: ['./menu-details.component.css']
})
export class MenuDetailsComponent implements OnInit {

  menuItem:Menu;
  description;
  selectedFile;
  isCapatchaResolved:boolean
  captchaKey:string;

  constructor(private data:DataService,private http:HttpClient,private location: PlatformLocation) 
  { 
    this.isCapatchaResolved=false;
    this.menuItem=this.data.Storage;
    this.captchaKey=captchaTest.Key
    this.location.onPopState(() => this.close());
  }

  ngOnInit() {
   
    if(this.menuItem.textFile){
    this.http.get<string>(this.menuItem.textFile,{responseType: 'text' as 'json'}).subscribe((data=>{
      this.description=data;
    }))
  }
  
  }

  private close(): any {
    $('#capatchaModal').modal('hide');
  }

  private downloadFile(fileUrl)
  {
   window.open(fileUrl);
  }

  resolved(captchaResponse: string) 
  {
    this.isCapatchaResolved=true;
  }

 selectFile(fileUrl)
 {
  this.selectedFile=fileUrl;
 }

downloadIfResolved()
{
  if(this.isCapatchaResolved)
  {
    if(this.selectedFile)
    this.downloadFile(this.selectedFile)
  }
}

}
