import { Injectable } from '@angular/core';
import { Brand } from 'src/app/Models/BrandsModel';
import { AzureBlobService } from '../AzureBlobServices/azure-blob.service';
import { azureEndPoints } from 'src/app/AzureConfig/AzureEndPoints';
import { HttpClient } from '@angular/common/http';
import { ImageModel } from 'src/app/Models/ImageModel';



@Injectable({
  providedIn: 'root'
})
export class GraphicsService {
  grubHubFolderName: string = "GrubHub Images";
  doorDashFolderName: string = "DoorDash Images";
  uberEatsFolderName: string = "UberEats Images";
  constructor(private azureBlobService:AzureBlobService,private http:HttpClient) 
  { 
   
  }
  async getBrandsData() {

    var BrandsList = new Array<Brand>();
    var blobsList;
    var brandNameList = new Array<string>();
    var brandsList = new Array<string>();

    var brandNameListOrdered = new Array<string>();

    await this.azureBlobService.getAllBlobs(azureEndPoints.azureBlobs.graphicsContainer).then((result) => {
      blobsList = result;
    })

    blobsList.forEach(element => {
      if (element.split('/')[0] == "Brands")
        brandsList.push(element);
    });

    // Code commented By Sanjiv - US96434 - AllBrands
    //brandsList.forEach(element => {
    // brandNameList.push(element.split('/')[1]);  
    //});

    brandNameList.push("All Brands"); // By Sanjiv - US96434 - AllBrands

    brandNameList.push("Coca-Cola");
    brandNameList.push("Coca-Cola Zero Sugar");
    brandNameList.push("Diet Coke");
    brandNameList.push("Sprite");
    brandNameList.push("Coca-Cola Cherry");
    brandNameList.push("Dr Pepper");
    brandNameList.push("Pibb Xtra");
    brandNameList.push("Fanta Orange");
    brandNameList.push("Minute Maid");

    brandNameList.push("Sprite Zero");
    brandNameList.push("Diet Dr Pepper");
    brandNameList.push("Mello Yello");
    brandNameList.push("Barq's");
    brandNameList.push("Seagram's Ginger Ale");

    brandNameList.push("Dasani");
    brandNameList.push("Smartwater");

    brandNameList.push("Monster");
    brandNameList.push("NOS");
    brandNameList.push("Full throttle");

    brandNameList.push("Sun Drop");
    brandNameList.push("Powerade");
    brandNameList.push("Gold Peak Tea");
    brandNameList.push("Tum-E-Yummies");
    brandNameList.push("Fairlife");

    // Code commented By Sanjiv - US96434 - AllBrands
    //brandNameList = Array.from(new Set(brandNameList.map((item: any) => item)))
    ////sorting brand names
    //brandNameList = brandNameList.sort(function (a, b) {
    // return a.localeCompare(b);
    //});

    brandNameList.forEach((itemBrandName) => {

      var graphicsSingleFolder = brandsList.filter((ele) => ele.split('/')[1] == itemBrandName)

      var brandItem = new Brand();
      brandItem.brandName = itemBrandName;
      if (itemBrandName == "All Brands")
        brandItem.checked = true;
      else
        brandItem.checked = false; // By Sanjiv - US96434 - AllBrands
      brandItem.brandLogos = new Array<string>();
      brandItem.brandProducts = new Array<string>();
      brandItem.other = new Array<string>();
      brandItem.brandUberEats = new Array<string>();
      brandItem.brandDoorDash = new Array<string>();
      brandItem.brandGrubhub = new Array<string>();

      graphicsSingleFolder.forEach((item) => {
        if (item.split('.')[1] == "png" || item.split('.')[1] == "jpg") {

          var brandSpacificFolder = item.split('/')[2];

          if (brandSpacificFolder == 'Product Images') {

            var itemUrl = this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer, item)
            brandItem.brandProducts.push(itemUrl)
          }
          else if (brandSpacificFolder == 'Logos') {

            var itemUrl = this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer, item)
            brandItem.brandLogos.push(itemUrl)
          }
          else if (brandSpacificFolder == 'Other') {

            var itemUrl = this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer, item)
            brandItem.other.push(itemUrl)
          }
          //else if (brandSpacificFolder == 'UberEats Images') {
          //  var itemUrl=this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer,item)
          //  brandItem.brandUberEats.push(itemUrl)
          //}

          //else if (brandSpacificFolder == 'DoorDash Images') {
          //  var itemUrl=this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer,item)
          //  brandItem.brandDoorDash.push(itemUrl)
          //}

          //else if (brandSpacificFolder == 'GrubHub Images') {
          //  var itemUrl=this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer,item)
          //  brandItem.brandGrubhub.push(itemUrl)
          //}

          //brandItem.brandProducts = brandItem.brandProducts.sort(function (a, b) {
          //  return a.localeCompare(b);
          //});
          //brandItem.brandLogos = brandItem.brandLogos.sort(function (a, b) {
          //  return a.localeCompare(b);
          //});
          //brandItem.brandUberEats = brandItem.brandUberEats.sort(function (a, b) {
          //  return a.localeCompare(b);
          //});
          //brandItem.brandDoorDash = brandItem.brandDoorDash.sort(function (a, b) {
          //  return a.localeCompare(b);
          //});
          //brandItem.brandGrubhub = brandItem.brandGrubhub.sort(function (a, b) {
          //  return a.localeCompare(b);
          //});
        }

      })

      BrandsList.push(brandItem);
    })

    BrandsList.sort((a, b) => {
      return brandNameList.indexOf(a.brandName) - brandNameList.indexOf(b.brandName);
    });

    return BrandsList;
  }

    download (url: string) {
      return fetch(url).then(resp => resp.blob()); 
    };

    downloadImage(imageUrl: string) {
      return this.http.get(imageUrl, {observe: 'response', responseType: 'blob'})
  }


    async getDefaultLogosData()
    {
     
    var blobsList;
    var LogosList=new Array<ImageModel>();

    await this.azureBlobService.getAllBlobs(azureEndPoints.azureBlobs.graphicsContainer).then((result)=>{
      blobsList=result;  
    })
  
    blobsList.forEach(element => {
      if(element.split('/')[0]=="Default Logos"){
        let model = new ImageModel();
        model.IsOtherImage = false;
        model.URL = this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer,element);
        LogosList.push(model);  
      }   
    });
    //LogosList = LogosList.sort(function (a, b) {
    //  return a.localeCompare(b);
    //});
     return LogosList;
    }
   // By Sanjiv - US96434 - AllBrands
  async getDefaultProductsandLogosandOtherImagesData() {

    var blobsList;
    var ProductsImagesList = new Array<ImageModel>();

    await this.azureBlobService.getAllBlobs(azureEndPoints.azureBlobs.graphicsContainer).then((result) => {
      blobsList = result;
    })

    blobsList.forEach(element => {
      let model = new ImageModel();
      if (element.split('/')[0] == "Default Logos") {
        model.IsOtherImage = false;
        model.URL = this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer, element);
        ProductsImagesList.push(model);
      }
      else if (element.split('/')[0] == "Default PI") {
        model.URL = this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer, element);
        model.IsLogosImage = false;
        model.IsOtherImage = false;
        ProductsImagesList.push(model);
      }
      else if (element.split('/')[0] == "Default Other") {
        model.URL = this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer, element);
        model.IsLogosImage = false;
        ProductsImagesList.push(model);
      }  
    });

    return ProductsImagesList;
  }


  async getDefaultProductsImagesData() {

    var blobsList;
    var ProductsImagesList = new Array<ImageModel>();

    await this.azureBlobService.getAllBlobs(azureEndPoints.azureBlobs.graphicsContainer).then((result) => {
      blobsList = result;
    })

    blobsList.forEach(element => {
      if (element.split('/')[0] == "Default PI") {
        let model = new ImageModel();
        model.URL = this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer, element);
        model.IsLogosImage = false;
        model.IsOtherImage = false;
        ProductsImagesList.push(model);
      }
    });
    //ProductsImagesList = ProductsImagesList.sort(function (a, b) {
    //  return a.localeCompare(b);
    //});
    return ProductsImagesList;
  }

  async getDefaultOtherImagesData() {
    var blobsList;
    var otherImagesList = new Array<ImageModel>();

    await this.azureBlobService.getAllBlobs(azureEndPoints.azureBlobs.graphicsContainer).then((result) => {
      blobsList = result;
    })

    blobsList.forEach(element => {
      if (element.split('/')[0] == "Default Other") {
        let model = new ImageModel();
        model.URL = this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer, element);
        model.IsLogosImage = false;
        otherImagesList.push(model);
      }
    });

    return otherImagesList;
  }

    
  //  async getDefaultUberEatsData()
  //  {
     
  //  var blobsList;
  //  var UberEatsList=new Array<string>();

  //  await this.azureBlobService.getAllBlobs(azureEndPoints.azureBlobs.graphicsContainer).then((result)=>{
  //    blobsList=result;  
  //  })
  
  //  blobsList.forEach(element => {
  //    if (element.split('/')[0] == this.uberEatsFolderName){
  //      var elementURL=this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer,element)
  //      UberEatsList.push(elementURL);  
  //    }
  //  });
  //    UberEatsList = UberEatsList.sort(function (a, b) {
  //      return a.localeCompare(b);
  //    });
  //   return UberEatsList;
  //  }

  //async getDefaultDoorDashData()
  //  {
     
  //  var blobsList;
  //  var DoorDashList=new Array<string>();

  //  await this.azureBlobService.getAllBlobs(azureEndPoints.azureBlobs.graphicsContainer).then((result)=>{
  //    blobsList=result;  
  //  })
  
  //  blobsList.forEach(element => {
  //    if (element.split('/')[0] == this.doorDashFolderName){
  //      var elementURL=this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer,element)
  //      DoorDashList.push(elementURL);  
  //    }
  //  });
  //    DoorDashList = DoorDashList.sort(function (a, b) {
  //      return a.localeCompare(b);
  //    });
  //   return DoorDashList;
  //  }

  //async getDefaultGrubhubData()
  //  {
     
  //  var blobsList;
  //  var GrubhubList=new Array<string>();

  //  await this.azureBlobService.getAllBlobs(azureEndPoints.azureBlobs.graphicsContainer).then((result)=>{
  //    blobsList=result;  
  //  })
  
  //  blobsList.forEach(element => {
  //    if (element.split('/')[0] == this.grubHubFolderName){
  //      var elementURL=this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer,element)
  //      GrubhubList.push(elementURL);  
  //    }
  //  });
  //    GrubhubList = GrubhubList.sort(function (a, b) {
  //      return a.localeCompare(b);
  //    });
  //   return GrubhubList;
  //  }

  }












