import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../components/login/login.component';
import { GraphicsComponent } from '../components/graphics/graphics.component';
import { MenuDetailsComponent } from '../components/menu-details/menu-details.component';
import { UploadComponent } from '../components/upload/upload.component';
import { HomeComponent } from '../components/home/home.component';
import { DirectAccessGuard } from './DirectAccessGuard';
import { NotFoundComponent } from '../components/not-found/not-found.component';
import { MenusListComponent } from '../components/menus-list/menus-list.component';
import { AuthGuard } from './AuthGuard';

const routes: Routes = [
  //{ path:'login',component:LoginComponent },
  { path: 'login', component: HomeComponent, canActivate: [DirectAccessGuard] },
  { path:'home',component:HomeComponent,canActivate:[DirectAccessGuard] },
  { path:'menus',component:MenusListComponent,canActivate:[DirectAccessGuard] },
  { path:'graphics',component:GraphicsComponent,canActivate:[DirectAccessGuard] },
  { path:'menuDetails',component:MenuDetailsComponent,canActivate:[DirectAccessGuard] },
  { path: 'upload',component:UploadComponent,canActivate:[AuthGuard] },
  { path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path:"**",component:NotFoundComponent
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ]
})
export class AppRoutingModule { }
