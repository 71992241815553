import { Component, OnInit } from '@angular/core';
import { Customer } from 'src/app/Models/CustomerModel';
import { CustomersService } from 'src/app/Services/CustomersService/customers.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  customer:Customer;
  constructor(private customerService:CustomersService,private router: Router) 
  { 
    this.customer=new Customer();
  }

  ngOnInit() {

  }

  contine(signinForm)
  {
    this.customerService.addCustomer(this.customer).subscribe(data=>{
      this.router.navigate(['home'])

    },(error)=>{
      console.log(error)
      alert("Error when navigating");
    });
  }
}
